<script>
import AuthLayout from '@app/layouts/AuthLayout.vue';
import ResetPasswordForm from '@shared/components/ResetPasswordForm.vue';

export default {
  head: {
    title: 'Changement de mot de passe',
  },
  components: { AuthLayout, ResetPasswordForm },
};
</script>

<template>
  <AuthLayout class="page">
    <template #menu>
      <router-link to="/connexion">
        Connexion
      </router-link>
    </template>

    <template #default>
      <ResetPasswordForm :to="{name: 'login'}" />
    </template>

    <template #title>
      Réinitialisez votre mot de passe
    </template>
    <template #description>
      Teachizy c'est : Zéro installation, des mises à jour gratuites régulières, sans aucun engagement, des transactions sécurisées et le tout, par une <strong class="has-text-link">entreprise 100% Française</strong>.
    </template>

    <template #figure>
      <BaseLazyImg
        class="w-full"
        :src="require('@app/assets/img/illus/content.png')"
        :src-low="require('@app/assets/img/illus/content.png?lqip')"
        :src-webp="require('@app/assets/img/illus/content.png?webp')"
      />
    </template>
  </AuthLayout>
</template>
